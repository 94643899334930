@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import "react-datepicker/dist/react-datepicker.css";
@import "variables";
@import "~bootstrap/scss/bootstrap";

body { font-family: 'Inter', sans-serif;  }
.navbar { padding: 32px 0;}
.topbtn { padding-left: 20px; }
.section { padding: 60px 0;}
a, .btn-link { text-decoration: none;}
h1, .h1 { font-weight: 700; margin-bottom: 1rem; } //48
h2, .h2 { font-size: 2.25rem; font-weight: 700; margin-bottom: 2rem; line-height: 1.125; } //36
h3, .h3 { font-size: 1.5rem; font-weight: 700; } //24
h4, .h4 { font-size: 1.125rem; font-weight: 700; } //18
.heroimg { transform: translateX(-30%); position: relative; z-index: -1; }
p { font-size: 18px; line-height: 1.5; color: #757575; margin-bottom: 2rem;}
ul.homelist li { font-size: 18px; line-height: 1.5; color: #757575;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
input[type=number] { -moz-appearance: textfield; appearance: textfield;  }
textarea { resize: none; }
.navbar-collapse { z-index: 9 !important;}
.btn-outline-primary { --bs-btn-color : $dark; border-color: transparent !important; background: linear-gradient(#fff, #fff) padding-box, linear-gradient(228.47deg, #2400FF 15.03%, #BC10D5 93.09%) border-box;}
.btn-outline-primary:hover, .btn-outline-primary:focus { background: linear-gradient($info-light,$info-light) padding-box, linear-gradient(228.47deg, #2400FF 15.03%, #BC10D5 93.09%) border-box; --bs-btn-hover-color : $dark; }
.form-control[readonly]:focus { box-shadow: none; border-color: #D9D9D9;}

.btn.btn-link, .btn.btn-link, fieldset .btn.btn-link { border: 0;}
.btn.btn-link:disabled, .btn.btn-link.disabled, fieldset:disabled .btn.btn-link { border: 0; color: #999 !important;}

.navbar-toggler:focus { box-shadow: none;}
.navbar-toggler { border:0}
.navbar-toggler.collapsed .navbar-toggler-icon { background: url('../images/navbar-toggle-icon.svg') no-repeat center center; }
.navbar-toggler .navbar-toggler-icon { background: url('../images/close-icon.svg') no-repeat center center; }
.navbar-expand .navbar-nav.dashmenu .nav-link { padding-left: 0; padding-right: 0; margin: 0 1rem; }
.dashmenu .nav-link.active { border-bottom-width: 2px; border-bottom-style: solid; border-image:linear-gradient(to right, #BC10D5, #2400FF) 1; }

.hero p { max-width: 500px;}
.hero .d-grid, .big-btn { max-width: 248px; }

// datepicker
.react-datepicker-popper { z-index: 2;}
.react-datepicker { border:0; font-family: 'Inter', sans-serif; }
.react-datepicker__month { margin: 0; text-align: center; }
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header { font-weight: 400; font-size: 18px; }
.react-datepicker__month-container { border: 1px solid #D9D9D9; border-radius: 10px; }
.react-datepicker__header { background: none; border: 0; padding: 16px 16px 8px; text-align: left; }
.react-datepicker__current-month { padding-left: 10px; }
.react-datepicker-time__header { padding-left: 0; }
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name { margin: 5px; width: 32px; height: 32px; line-height: 32px; color: #333333; }
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled { color: #d9d9d9;  }
.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
    border-radius: 100%;
    background-color: #f3f3f3;
    color: #666666;
}
.react-datepicker__navigation-icon::before { border-width: 2px 2px 0 0; }
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) { right: auto; top: 15px; left: 290px; }
.react-datepicker__navigation--previous { left: 250px; top: 15px; }
.react-datepicker__navigation--next { right: 0px; top: 15px; }
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text { display: inline-block; width: 4rem; margin: 7px; height: 3rem;
    vertical-align: middle; line-height: 3.5; color: #D9D9D9; border-radius: 5px; }
.react-datepicker__month .react-datepicker__day--keyboard-selected, .react-datepicker__month  .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #000; border: 1px solid #000; color: #fff;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 100%;

}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
border-radius: 100%; background-color: #000; border: 1px solid #000; color: #fff; }


.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
border-radius: 100%; background-color: #000; color: #fff; }
.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover,
.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
border-radius: 5%; background-color: #000; color: #fff;    
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
    background-color: transparent; color: #D9D9D9;
}

.react-datepicker__time-container { border: 1px solid #D9D9D9; border-radius: 10px; width: 322px; margin-left: 16px; } 
.react-datepicker__time-container .react-datepicker__time { border-radius: 10px; padding: 0 16px; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box { width: 100%; }
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
height: 48px; font-size: 14px; color: #000; border: 1px solid #D9D9D9; border-radius: 10px; margin-bottom: 8px; line-height: 36px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: #000; color: #fff;
}

.sloginbox { max-width: 375px; padding: 30px; }
.orline { position: relative; margin: 2rem 0; text-align: center; }
.orline:before { content: ''; width: 100%; height: 1px; background: #9FA3A9; position: absolute; left: 0; top: 50%; transform: translateY(-50%); }
.orline span { background: #ffffff; padding: 0 15px; display: inline-block; position:  relative;}
.form-control-cover { position: relative;} 
.form-control-cover .form-label { position: absolute; pointer-events: none; transform: translate(0, 16px) scale(1); transform-origin: top left; transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
line-height: 1; left: 16px; top:0; z-index: 1; font-size: 0.875rem; }
.form-control-cover .form-label.filled,
.form-control-cover:focus-within .form-label  { transform: translate(0px, 8px) scale(0.8); }
.form-control-cover .formicon { position: absolute; top:16px; right: 16px; }

.form-control-cover .form-control { padding: 20px 16px 6px 16px; }
.form-control-cover .form-select { padding: 20px 48px 6px 16px; }
.stepcover .form-check.designed { padding: 0;}
.stepcover .form-check.designed label { border:1px solid #cccccc; border-radius: 10px; padding: 16px; margin-bottom: 8px; text-align: left; position: relative; cursor: pointer; }
.stepcover .form-check.designed p { margin-bottom: 0;}
.stepcover .designed .form-check-input { position: absolute; opacity: 0;}
.stepcover { max-width: 328px; margin: 0 auto; }
.stepcover .form-check.designed input:checked + label{ background: #E9E5FF; }
.stepcover .backbtn { position: absolute; top: -50px; left: 12px; }

.backdrop { position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 1; }
.avaswitch { position: relative; }
.avaswitch input { position: absolute; opacity: 0; }
.avaswitch label { background: #FFFFFF; border: 1px solid #CCCCCC; border-radius: 100px; position: relative; cursor: pointer; transition: all 0.3s ease-in }
.avaswitch label::before { content: 'Unavailable'; font-size: 12px; font-weight: 700; color: #999999; padding: 6px 12px; width: 126px; display: inline-block; left:20%; transition: all 0.2s cubic-bezier(0.24,1.74,0.92,0.85); position: relative; }
.avaswitch label::after { content: ''; width: 24px; height: 24px; background: #999999; position: absolute; left: 4px; top: 3px; border-radius: 100%; transition: all 0.2s cubic-bezier(0.24,1.74,0.92,0.85) }
.avaswitch input:checked + label { background: #000; }
.avaswitch input:checked + label::before { content: 'Available'; left:0; color: #fff; }
.avaswitch input:checked + label::after { left: 75%; background: #ffffff; }

.avaswitch.disly label {padding: 0 12px;}
.avaswitch.disly label::before { content: 'Lively Disabled'; left: 12%; }
.avaswitch.disly input:checked + label::before { content: 'Lively Enabled'; left:0; color: #fff; }
.avaswitch.disly input:checked + label::after { left: 77%; }

.topnotification button { 	border: 1px solid #F3F3F3; background: none; width: 48px; height: 48px; border-radius: 100%; overflow: hidden; }
.topnotification button:hover { background: #E9E5FF; }
.profilepic button { border: 0; background: none; display: flex; align-items: center; }
.profilepic button  span { display: block; width: 48px; height: 48px; overflow: hidden; border-radius: 100%; margin-right: 0.5rem; }
.profilepic button img { width: 100%; height: 100%; object-fit: cover;  }

.topnotification,
.profilepic { position: relative; }
.notificationmenu,
.profilemenu { background: #FFFFFF; border: 1px solid #CCCCCC; border-radius: 10px; position: absolute; height: 80vh; overflow-y: auto; right: 0; width: 328px; top: 56px; z-index: 9; }
.profilemenu ul { padding: 20px 0; list-style-type: none; margin: 0; }
.profilemenu ul li a { padding: 20px 20px; display: block; line-height: 1.15; font-size: 14px; color: #000; font-weight: 700; text-align: center; }

.notificationmenu { right: -100px;}
.notificationmenu ul { margin: 0; padding: 0; }
.notificationmenu ul li { list-style-type: none; padding: 8px 16px 8px 32px; position: relative; }
.notificationmenu ul li::before { content: ''; width: 7px; height: 7px; background: #000; border-radius: 100%; position: absolute; left: 16px; top: 14px; }
.notificationmenu ul li:hover { background-color: #E9E5FF;}
.growdown { transform: rotate(180deg);  }
.growdown path { fill: #ff0000; }

.teamlistitem .imgbox { width: 32px; height: 32px; border-radius: 100%; overflow: hidden; margin: 4px 0; flex: 0 0 32px;}
.prolistitem .imgbox { width: 40px; height: 40px; border-radius: 8px; overflow: hidden; flex: 0 0 40px; }
.settingsprofilepic .imgbox { width: 48px; height: 48px; border-radius: 100%; overflow: hidden; flex: 0 0 48px; }
.settingsprofilepic .imgbox img,
.prolistitem .imgbox img,
.teamlistitem .imgbox img { width: 100%; height: 100%; object-fit: cover;}
.sessiongrid > div { width: 100%;}
.sessiongrid .t1 { max-width: 262px; }
.sessiongrid .t2 { max-width: 202px; }
.sessiongrid .t3 { max-width: 149px; }
.sessiongrid .t4 { max-width: 40px; }
.sessiongrid .t5 { max-width: 110px; }
.sessiongrid .t6 { max-width: 120px; }
.sessiongrid .t2 > div { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.recentsession .sessiongrid .t1 { max-width: 162px; }
.recentsession .sessiongrid .t2 { max-width: 150px; }
.recentsession .sessiongrid .t3 { display: none !important;}


.dashbox { height: 198px; overflow: hidden; }
.progresscover { width: 200px; margin: 0 auto; overflow: hidden; height: 120px; }
.CircularProgressbar-text { transform: translate(-10px, -2px); font-size: 12px; font-weight: 700; }

.selectdatemodal .modal-dialog { max-width: 375px;}
.selectdatemodal h2 { font-size: 2rem;}

.slidedown-enter { opacity: 0; transform: translateY(-50px); }
.slidedown-enter-active { opacity: 1; transition: all 0.2s ease-in; transform: translateY(0px); }
.slidedown-exit { opacity: 1; transform: translateY(0px); }
.slidedown-exit-active { opacity: 0; transition: all 0.2s ease-in; transform: translateY(-50px); }

.slideup-enter { opacity: 0; transform: translateY(50px); }
.slideup-enter-active { opacity: 1; transition: all 0.2s ease-in; transform: translateY(0px); }
.slideup-exit { opacity: 1; transform: translateY(0px); }
.slideup-exit-active { opacity: 0; transition: all 0.2s ease-in; transform: translateY(50px); }

// sessions page
.seesionleft { flex: 0 0 346px; max-width: 346px;}
.grayarrow path { stroke: #666666; }
.whitearrow path { stroke: #fff;}

.bigbtn { max-width: 328px; }
.livevideocontainer { display: flex; max-width: 1312px; margin: 0 auto; justify-content: space-between; position: relative; }
.videobox { flex: 1; }
.livecartbox { max-width: 328px; flex: 0 0 328px; margin-left: 16px; background: #fff;}
.proimgbox { height: 256px; }
.proimgbox img { width: 100%; height: 256px; object-fit: cover; }
.prolistscrollcover { flex: 1; }
.prolistscroll {
	max-height: 360px;
	overflow-y: auto;
}
.colorradio span { width: 32px; height: 32px; background: #fff; border-radius: 8px; display: block; font-size: 0; border:2px solid #fff; cursor: pointer; margin-right: 8px; }
.colorradio input[type="radio"]{
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border-width: 0;
}
.colorradio input:checked + span { box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(156, 163, 175) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;  }

.sizeradio span { width:auto; min-width: 32px; height: 32px; border-radius: 8px; border:1px solid transparent; display: flex; align-items: center; justify-content: center; cursor: pointer; margin-right: 8px; font-size: 11px; font-weight: semibold; padding: 0 5px; }
.sizeradio input[type="radio"]{
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border-width: 0;
}
.sizeradio input:checked + span { border:1px solid #666666;  }

// ::-webkit-scrollbar { display: none;}
/* width */
::-webkit-scrollbar { width: 2px; }
/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; }
/* Handle */
::-webkit-scrollbar-thumb { background: #888; }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; }

.prolistscroll { -ms-overflow-style: none; scrollbar-width: thin;}
.commonloader { z-index: 999999;}

.qtybox .form-control { display: inline; max-width: 70px; padding: 8px 16px; text-align: center; }

.recharts-tooltip-wrapper:focus-visible { outline: 0; }
.custom-tooltip, .custom-tooltip:hover, .custom-tooltip:focus-visible { background: #000; border-radius: 8px; padding: 5px 20px; outline: 0; border:0; box-shadow: none; }
.custom-tooltip p { color: #fff; margin: 0; font-size: 12px;}

.footer-links { text-align: right;}
.modal-content .btn { z-index: 9; }
.mobilecartbtn {
	position: relative;
}
.mobilecartbtn span {
	background: #fff;
	border-radius: 100%;
	color: #000;
	width: 24px;
	height: 24px;
	display: block;
	position: absolute;
	right: 16px;
	top: 12px;
}
.cartheight {
    max-height: 380px;
    overflow-y: auto;
    padding-right: 15px;
}

#my-form .form-control:disabled { background: #ffffff; }
#my-form .form-control.phinput[readonly]
 {
    -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  pointer-events: none;
  }

@media only screen and (max-width:992px) { 
    .seesionleft { flex: 0 0 100%; max-width: 100%;}
    .livecartbox { position: fixed; bottom: 12px; max-width: 100%; flex: 0 0 100%; margin: 0; z-index: 99; height: 86vh; left: 12px; right: 12px; }
    .sessiongrid .t1 { max-width: 100%; }
    .sessiongrid .t2 { max-width: 100%; }
    .sessiongrid .t3 { max-width: 149px; }
    .sessiongrid .t4 { max-width: 40px; }
    .sessiongrid .t5 { max-width: 110px; }
    .sessiongrid .t6 { max-width: 120px; }
    .myCallContainer { 
        position: fixed; left: 0; top: 0; right: 0; bottom: 0;
    }
    .bottombtn { 
        bottom:80px !important;
    }
}

@media only screen and (max-width:767px) {
    .navbar-collapse {
        position: fixed;
        top: 80px;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 1;
        text-align: center;
    }
    .navbar-nav { padding-top: 80px;}
    .navbar-nav .nav-link { padding: 15px 0; }
    .topbtn { margin-top: 25px;}
	h2, .h2 { font-size: calc(1.79rem + 0.9vw); }
	.heroimg svg { width: 100%; height: auto; }
	.heroimg { transform: none; margin-top: 30px;}
    // .avaswitch label { width:56px; height: 32px;}
    .avaswitch.disly label { width:auto;}
    // .avaswitch input:checked + label::after { left: 47%;}
    // .avaswitch input:checked + label::before,
    // .avaswitch label::before { content: none;}
	.react-datepicker__month-container { margin-right: 0px; float: none; margin-bottom: 16px;}
	.react-datepicker__time-container { float: none; }
    .availbilitylistcheck { position: absolute; right: 30px; top: 0; }
    .removexsborder { border-width: 0 !important;}
    .prolistscroll { max-height: 63vh; }
    .footer { text-align: center;}
    .footer-links { text-align: center; margin: 30px 0 0; display: flex; flex-direction: column;}
    .footer-links a { margin: 15px 0; }
    .availabilitycover .form-select { padding-left:0.5rem; padding-right: 2rem; background-position: right 0.5rem center; }
}